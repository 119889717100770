<template>
    <section class="error-content">
        <div class="error-content-wrapper">
        <h1 class="title2">OOPS!</h1>
        <p class="main-text">La page ne peut pas être trouvée / This page can't be found</p>
        <a href="/">Retour / Back</a>
        </div>
    </section>
</template>

<script>
export default {
    name: 'p404',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.error-content{height:100vh;min-height:375px;position:relative;background-image:url('https://source.unsplash.com/random/?abstract,grey');background-repeat:no-repeat;background-size:cover;background-position:center center}
  .error-content:before{content:"";position:absolute;top:0;left:0;bottom:0;right:0;width:100%;background-color:rgba(51,62,88,.7)}
  .error-content .error-content-wrapper{position:absolute;top:50%;left:50%;-ms-transform:translate(-50%,-50%);transform:translate(-50%,-50%);text-align:center}
  .error-content .error-content-wrapper .title2{margin-bottom:15px;color:#fff;font-family:essonnes-display,sans-serif;font-size:52px}
  .error-content .error-content-wrapper .main-text, .error-content .error-content-wrapper a{letter-spacing:.05em;font-weight:500;color:#fff;margin-top:10px}
  .error-content .error-content-wrapper a{border-bottom:1px solid #fff;display:inline-block;cursor:pointer}

  @media only screen and (max-width: 599px){
    .error-content .error-content-wrapper .title2{font-size:32px}
    .error-content .error-content-wrapper .main-text,.error-content .error-content-wrapper a{font-size:14px}
    .error-content .error-content-wrapper{width:100%;padding:20px}
  }
</style>
